<template>
  <div class="purchase-invoicing">
    <!-- 表单 -->
    <div class="facilityBox">
      <div style="position: absolute; right: 20px; top: 125px; z-index: 999">
        <el-tooltip placement="top-end" effect="light">
          <div slot="content">
            <div
              style="
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 8px;
              "
              v-for="(it, idx) in tips"
              :key="idx"
            >
              <div style="font-weight: bold; width: 70px">{{ it.title }}</div>
              <div>{{ it.con }}</div>
            </div>
          </div>
          <div style="margin: 0px 20px; color: #0f5fff">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>
      <el-tabs v-model="activeCmpt" @tab-click="changeTab">
        <el-tab-pane label="上传发票" name="SellersUpload"></el-tab-pane>
        <el-tab-pane label="已开发票" name="SellersAlready"></el-tab-pane>
        <el-tab-pane label="完成开票" name="SellersComplete"></el-tab-pane>
        <el-tab-pane label="取消/放弃" name="SellersCancel"></el-tab-pane>
      </el-tabs>
      <el-form class="queryForm" :model="manageForm" ref="manageForm">
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item label="开票申请编号" prop="bsid">
            <el-input
              class="fromInp"
              v-model="manageForm.bsid"
              placeholder="请输入开票申请编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="业务类型" prop="businessType">
            <el-select
              v-model="manageForm.businessType"
              placeholder="请选择业务类型"
              @change="businessChange"
              clearable
            >
              <el-option
                v-for="item in businessList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场景类型" prop="scenarioType">
            <el-select
              v-model="manageForm.scenarioType"
              placeholder="请选择场景类型"
              clearable
              @change="sceneTypeChange"
            >
              <el-option
                v-for="item in sceneList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票类型" prop="invoiceType">
            <el-select
              v-model="manageForm.invoiceType"
              placeholder="请选择发票类型"
              clearable
            >
              <el-option
                v-for="item in invoiceList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="16px">
            <el-button type="primary" @click="search()" icon="el-icon-search"
              >搜索
            </el-button>
            <el-button type="primary" @click="clear()" icon="el-icon-delete"
              >清空
            </el-button>
          </el-form-item>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item label="货主单位" prop="ApplicantUserName">
            <el-input
              v-model="manageForm.ApplicantUserName"
              placeholder="请输入货主单位"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="当前进度" prop="statusList">
            <el-select
              v-model="manageForm.statusList"
              placeholder="请选择当前进度"
              clearable
            >
              <el-option
                v-for="item in statusList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Vals"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式" prop="PayModeID">
              <el-select v-model="manageForm.PayModeID" placeholder="请选择支付方式" clearable @change="search">
                  <el-option v-for="item in PayModeIDList" :key="item.Code" :label="item.Name"
                      :value="item.Code"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="时间筛选" prop="loadingTime">
            <el-date-picker
              v-model="manageForm.loadingTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        key="tableData"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BSID"
          label="开票申请编号"
          align="center"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              style="color: #0f5fff"
              @click="openDetail(scope.row, 'billInfo')"
              >{{ scope.row.BSID }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="ApplicantUserName"
          label="货主单位"
          width="200"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceTypeName"
          label="发票类型"
          width="150"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BusinessTypeName"
          label="业务类型"
          width="150"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ScenarioTypeName"
          label="场景类型"
          width="150"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceKindName"
          label="专票普票"
          width="150"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BillCount"
          label="总计运单数量"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="SuplierRateStr"
          label="费率"
          width="150"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.SuplierRateStr }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="BaseAmount"
          label="运费"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template>
            <span style="color: #ff8b17">/</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SupplierProfit"
          label="服务费/税费"
          width="150"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.SupplierProfit | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="运单总金额"
          width="150"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.TotalAmount | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="PayModeName"
          label="付款方式"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ApplyTime"
          label="付款时间"
          width="150"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="StatusTime"
          label="状态变更时间"
          width="150"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="StatusName"
          label="当前进度"
          width="150"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="
                scope.row.Status != 1 &&
                scope.row.Status != 2 &&
                scope.row.Status != 21
              "
              >{{ scope.row.StatusName }}
            </el-tag>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">货主主动取消开票</div>
              <el-tag type="info" v-if="scope.row.Status == 1">{{
                scope.row.StatusName
              }}</el-tag>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content" style="width: 400px">
                {{ scope.row.RefuseRemark }}
              </div>
              <el-tag type="danger" v-if="scope.row.Status == 2">{{
                scope.row.StatusName
              }}</el-tag>
            </el-tooltip>
            <el-tag type="danger" v-if="scope.row.Status == 21">{{
              scope.row.StatusName
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="agreeInvoice(scope.row)"
              v-if="scope.row.Status == 0"
              >同意开票
            </el-button>
            <el-button
              type="text"
              @click="refuseInvoice(scope.row)"
              style="color: #ff2323"
              v-if="scope.row.Status == 0 || scope.row.Status == 10"
              >拒绝开票
            </el-button>
            <el-button
              type="text"
              @click="toInvoice(scope.row)"
              v-if="scope.row.Status == 10"
              >开具发票
            </el-button>
            <el-button
              type="text"
              @click="toInvoice(scope.row)"
              v-if="scope.row.Status == 19"
              >继续开票
            </el-button>
            <el-button
              type="text"
              @click="toInvoice(scope.row)"
              v-if="scope.row.Status == 20 || scope.row.Status == 22"
              >发送货主
            </el-button>
            <el-button
              type="text"
              @click="toInvoice(scope.row)"
              v-if="scope.row.Status == 21 || scope.row.Status == 25"
              style="color: #ff8b17"
              >重新开票
            </el-button>
            <el-button type="text" @click="openDetail(scope.row, 'invoiceInfo')"
              >查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 运单详情 -->
    <el-dialog
      title="查看详情"
      :visible.sync="detailTableDialog"
      v-if="detailTableDialog"
      width="1200px"
      :close-on-click-modal="false"
      class="detailTableDialog"
    >
      <!-- 合计 -->
      <div class="settleBox">
        <el-row>
          <el-col :span="8"
            ><strong>发票抬头：</strong>{{ settlementInfo.TitleName }}</el-col
          >
          <el-col :span="8"
            ><strong>单位地址：</strong>{{ settlementInfo.TitleAdress }}</el-col
          >
          <el-col :span="8"
            ><strong>纳税人识别号：</strong
            >{{ settlementInfo.TitleCreditCode }}</el-col
          >
        </el-row>
        <el-row style="margin: 24px 0px">
          <el-col :span="8"
            ><strong>联系电话：</strong>{{ settlementInfo.TitlePhone }}</el-col
          >
          <el-col :span="8"
            ><strong>开户银行：</strong
            >{{ settlementInfo.TitleBankerName }}</el-col
          >
          <el-col :span="8"
            ><strong>开户银行账号：</strong
            >{{ settlementInfo.TitleBankerNumber }}</el-col
          >
        </el-row>
        <el-row>
          <el-col :span="8">
            <strong>发票类型：</strong>
            <span v-if="settlementInfo.InvoicingType == 1"
              >运费-{{ settlementInfo.InvoiceKindName }}</span
            >
            <span v-else
              >运费-{{ settlementInfo.InvoiceKindName }} 技术服务费-{{
                settlementInfo.InvoiceKindTecName
              }}</span
            >
          </el-col>
          <el-col :span="8"
            ><strong>运单条数：</strong>{{ settlementInfo.BillCount }}条</el-col
          >
          <el-col :span="8"
            ><strong>支付方式：</strong>{{ settlementInfo.PayModeName }}</el-col
          >
        </el-row>
        <el-row style="margin-top: 24px">
          <el-col :span="8"
            ><strong>运费(元)：</strong>
            <span style="color: #ff8b17; font-weight: bold">/</span></el-col
          >
          <el-col :span="8"
            ><strong>服务费/税费(元)：</strong
            ><span style="color: #ff8b17; font-weight: bold">{{
              settlementInfo.SupplierProfit | formatMoney
            }}</span></el-col
          >
          <el-col :span="8"
            ><strong>运单总金额(元)：</strong
            ><span style="color: #ff8b17; font-weight: bold">{{
              settlementInfo.TotalAmount | formatMoney
            }}</span>
          </el-col>
        </el-row>
      </div>
      <el-table
        :data="detailTableList"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="detailTableLoading"
        key="detailTableList"
        v-if="
          activeCmpt == 'SellersUpload' ||
          activeCmpt == 'SellersCancel' ||
          dialogType == 'billInfo'
        "
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BBID"
          label="运单号"
          width="200"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ApplicantName"
          label="货主单位"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceTypeName"
          label="发票类型"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="Shipper"
          label="托运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerName"
          label="承运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerIDCard"
          label="承运人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerPhone"
          label="承运人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeName"
          label="代收人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeIDCard"
          label="代收人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="PayeePhone"
          label="代收人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipName"
          label="船舶名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsName"
          label="货物名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsUnitName"
          label="货物单位"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="OriginAddress"
          label="起始地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary" >{{ scope.row.OriginAddress }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ArriveAddress"
          label="到达地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary" >{{ scope.row.ArriveAddress }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="OriginTime"
          label="起始时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ArriveTime"
          label="到达时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="Counts"
          label="货物数量"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="Price"
          label="运输单价"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.Price | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CarriageFee"
          label="运费"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span
              style="color: #ff8b17"
              v-if="currentItem.TaskTypeID.includes('DK')"
              >{{ scope.row.CarriageFee | formatMoney }}</span
            >
            <span style="color: #ff8b17" v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SupplierProfit"
          label="服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.SupplierProfit | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.TotalAmount | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TrustFee"
          label="委托代开运费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="currentItem.TaskTypeID.includes('DK')"
        >
          <template>
            <span style="color: #ff8b17">/</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SupplierProfit"
          label="委托代开服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.SupplierProfit | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="委托代开运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.TotalAmount | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ThirdBillID"
          label="客户单号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
      </el-table>
      <el-table
        :data="currentTableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        key="tableData1"
        v-if="
          (activeCmpt == 'SellersAlready' || activeCmpt == 'SellersComplete') &&
          dialogType != 'billInfo'
        "
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BPID"
          label="发票任务号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BillCount"
          label="运单数量"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceNumber"
          label="发票号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="CreateTime"
          label="开票时间"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="FeeType"
          label="开票类型"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.FeeType == 0 ? "运费" : "技术服务费"
          }}</template>
        </el-table-column>
        <el-table-column
          prop="StatusName"
          label="当前进度"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.Status != 1 && scope.row.Status != 2"
              >{{ scope.row.StatusName }}
            </el-tag>
            <el-tag type="info" v-if="scope.row.Status == 1">{{
              scope.row.StatusName
            }}</el-tag>
            <el-tag type="danger" v-if="scope.row.Status == 2">{{
              scope.row.StatusName
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope"  v-if="scope.row.InvoiceNumber">
            <el-button
              type="text"
              @click="getUploadInvoiceList(scope.row)"
              :loading="flag.invoicePreviewLoading"
              style="color: #0f5fff"
              >查看发票
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentDetailChange"
          :current-page.sync="paginationDetail.page"
          :page-size="paginationDetail.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="paginationDetail.total"
          v-if="
            activeCmpt == 'SellersUpload' ||
            activeCmpt == 'SellersCancel' ||
            dialogType == 'billInfo'
          "
        >
        </el-pagination>
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentDetailChange1"
          :current-page.sync="paginationDetail1.page"
          :page-size="paginationDetail1.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="paginationDetail1.total"
          v-if="
            (activeCmpt == 'SellersAlready' ||
              activeCmpt == 'SellersComplete') &&
            dialogType != 'billInfo'
          "
        >
        </el-pagination>
      </div>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-button
          plain
          type="info"
          size="mini"
          @click="detailTableDialog = false"
          >关 闭</el-button
        >
        <el-button
          plain
          type="danger"
          size="mini"
          @click="refuseInvoice(currentItem)"
          v-if="currentItem.Status == 0"
          >拒绝开票
        </el-button>
        <el-button
          plain
          type="primary"
          size="mini"
          @click="flag.voucherDialog = true"
          >查看凭证</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="agreeInvoice(currentItem)"
          v-if="currentItem.Status == 0"
        >
          同意开票
        </el-button>
        <el-button
          type="primary"
          size="mini"
          @click="toInvoice(currentItem)"
          v-if="currentItem.Status == 10"
          >开具发票
        </el-button>
        <el-button
          type="primary"
          size="mini"
          @click="toInvoice(currentItem)"
          v-if="currentItem.Status == 19"
          >上传发票
        </el-button>
        <el-button plain size="mini" type="primary" @click="downloadFile('tax')"
          >下载税务表格</el-button
        >
        <el-button
          plain
          size="mini"
          type="primary"
          @click="downloadFile('bill')"
          >下载运单表格</el-button
        >
      </div>
    </el-dialog>
    <!-- 同意开票弹窗 -->
    <el-dialog
      :visible.sync="flag.agreeInvoiceDialog"
      v-if="flag.agreeInvoiceDialog"
      width="380px"
      :close-on-click-modal="false"
    >
      <div slot="title" style="display: flex; align-items: center">
        <i class="el-icon-success" style="color: #00e855; font-size: 24px"></i>
        <span style="font-size: 16px; font-weight: bold; margin-left: 4px"
          >同意开票</span
        >
      </div>
      <div style="margin-bottom: 22px; text-align: center">
        你确定同意为该货主进行开票吗?
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <el-checkbox v-model="isNotPromptAgain" @change="changeIsPrompt"
          >下次不再提示</el-checkbox
        >
        <span slot="footer" class="dialog-footer">
          <el-button plain type="info" @click="flag.agreeInvoiceDialog = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmAgreeInvoice"
            >同意开票</el-button
          >
        </span>
      </div>
    </el-dialog>
    <!-- 拒绝开票弹窗 -->
    <el-dialog
      :visible.sync="flag.refuseInvoiceDialog"
      v-if="flag.refuseInvoiceDialog"
      width="700px"
      :close-on-click-modal="false"
    >
      <div slot="title" style="display: flex; align-items: center">
        <i class="el-icon-error" style="color: #ff2323; font-size: 24px"></i>
        <span style="font-size: 16px; font-weight: bold; margin-left: 4px"
          >拒绝开票</span
        >
      </div>
      <div style="margin-bottom: 8px">
        <div
          v-if="choseRefuseMark.length"
          style="margin-bottom: 8px; font-weight: bold"
        >
          <div>您好：</div>
          <div
            v-for="(it, idx) in choseRefuseMark"
            :key="idx"
            style="margin-top: 8px"
          >
            {{ idx + 1 }}.{{ it }}
          </div>
        </div>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="可补充原因"
          v-model="refuseMark"
          maxlength="200"
        >
        </el-input>
      </div>
      <h3>快捷回复</h3>
      <div></div>
      <el-checkbox-group v-model="choseRefuseMark">
        <el-checkbox
          :label="it"
          v-for="(it, idx) in refuseMarkList"
          :key="idx"
          style="width: 300px; margin-top: 8px"
        ></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button plain type="info" @click="flag.refuseInvoiceDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmRefuseInvoice"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看/上传凭证dialog -->
    <el-dialog
      width="1200px"
      title="凭证"
      :visible.sync="flag.voucherDialog"
      append-to-body
      v-if="flag.voucherDialog"
      :close-on-click-modal="false"
    >
      <EvidenceInfo :businessID="currentItem.BSID"> </EvidenceInfo>
    </el-dialog>
    <!-- 发票包预览 -->
    <el-dialog
      width="1200px"
      title="查看发票"
      :visible.sync="flag.invoicePreviewDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div>
        <el-image
          style="width: 1100px; height: 500px"
          :src="invoicePics[currentPicIdx]"
          :preview-src-list="[invoicePics[currentPicIdx]]"
        >
        <div slot="error" class="image-slot imageError">
          <span>已由系统回填信息</span>
        </div>
        </el-image>
      </div>
      <div style="text-align: right; margin-top: 16px">
        <el-button
          type="info"
          plain
          size="medium"
          @click="flag.invoicePreviewDialog = false"
          >关闭
        </el-button>
        <el-button type="primary" plain size="medium" @click="toPrevious"
          >上一张
        </el-button>
        <el-button type="primary" plain size="medium" @click="toNext"
          >下一张
        </el-button>
        <el-button type="primary" size="medium" @click="downloadFile('invoice')"
          >下载全部发票
        </el-button>
      </div>
    </el-dialog>

    <el-dialog width="800px" :visible.sync="traceDialog" append-to-body class="deep_dialog">
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="traceDialog" ref="txMap" :list="mapList"> </TXmap>
    </el-dialog>
  </div>
</template>

<script>
// 当前进度Status对应值
// 0：已支付 1：货主取消开票 2：服务商拒绝开票 10：服务商已同意 19：组合发票暂存 20：服务商已开票完成 21：货主驳回发票 22：服务商已重开发票 28：服务商发送发票到货主 29：服务商发送发票到货主/重开 30：货主确认发票
import {
  GetBTaskType,
  GetStatementList,
  GetStatementDetailList,
  AgreeStatement,
  RefusedStatement,
  GetBStatementMenuStatus,
  GetStatementInfo,
  GetPaymentMethod ,
  getMapPath,
} from "@/api/purchaseManage/invoiceFlow";
import {
  GetUploadInvoiceList,
  DownloadInvoiceAll,
  GetInvoicePackages,
  DownloadTaxExcel,
  DownloadBillExcel,
} from "@/api/purchaseManage/markOut";
import TXmap from "@/components/commonCmpt/TXmap";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
export default {
  data() {
    return {
      mapList: [],
      traceDialog: false,
      isNotPromptAgain: false, // 是否不再提示
      tips: [
        {
          title: "上传发票：",
          con: "您可在此针对已付款的订单进行“开具发票”操作，也可进行“放弃开票”操作。",
        },
        {
          title: "已开发票：",
          con: "您已开具发票的所有订单在此查看，被货主驳回需重新开具发票的也在此进行操作。",
        },
        {
          title: "完成发票：",
          con: "您可在查看所有货主已确认收票的所有运单信息。",
        },
        {
          title: "取消/放弃：",
          con: "您可在此查看您放弃开票和货主取消开票的所有运单。",
        },
      ],
      flag: {
        refuseInvoiceDialog: false, // 拒绝开票弹窗
        agreeInvoiceDialog: false, // 同意开票弹窗
        voucherDialog: false, // 查看凭证弹窗
        invoicePreviewDialog: false, // 查看发票
        invoicePreviewLoading: false
      },
      invoicePics: [], // 发票数组
      currentPicIdx: "", // 当前预览的发票图片索引
      activeCmpt: "SellersUpload", // 切换tab
      // 筛选条件数据
      manageForm: {
        bsid: "", // 开票申请编号
        ApplicantUserName: "", // 货主单位
        businessType: "", // 业务类型
        scenarioType: "", // 场景类型
        invoiceType: "", // 发票类型
        loadingTime: ["", ""], // 时间筛选
        statusList: [],
        PayModeID:'',//支付方式
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      loading: false,
      currentItem: {},
      businessList: [], // 业务类型列表
      sceneList: [], // 场景类型列表
      invoiceList: [], // 发票类型列表
      statusList: [], // 进度列表
      detailTableDialog: false, // 结算单明细列表弹窗
      detailTableLoading: false,
      detailTableList: [],
      paginationDetail: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      detailInvoiceTableList: [],
      paginationDetail1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      refuseMark: "", // 拒绝开票原因
      refuseMarkList: [
        "发票数量不足，很抱歉！",
        "当前繁忙请延迟几天申请！",
        "结算单金额太大，请分割成多个结算单！",
        "个别运单超出可开票范围！",
        "个别司机申请开票金额太高！",
      ],
      choseRefuseMark: [], // 选中的拒绝开票快捷回复
      settlementInfo: {}, // 发票六要素
      dialogType: "", // 弹窗类型
      PayModeIDList: [], // 支付方式列表
    };
  },
  methods: {
    openMap(item) {
      this.flag.importListLoading = true;
      let params = {
        bbid: item.BBID,
      };
      getMapPath(params)
        .then((res) => {
          console.log(res);
          let { data } = res;
          this.mapList = data;
          this.traceDialog = true;
          console.log(  this.mapList )
        })
        .finally(() => {
          this.flag.importListLoading = false;
        });
    },
    // 切换tab
    changeTab() {
      this.$refs["manageForm"].resetFields();
      this.manageForm.statusList = [];
      this.search();
    },
    // 获取进度列表
    async getBStatementMenuStatus() {
      await GetBStatementMenuStatus({
        menuEnum: this.activeCmpt,
      })
        .then((res) => {
          this.statusList = res.data || [];
        })
        .finally(() => {
          this.getGoodsList();
        });
    },
    // 选择业务类型
    businessChange() {
      if (this.manageForm.businessType) {
        // 获取场景类型
        GetBTaskType({
          level: 2,
          code: this.manageForm.businessType,
        }).then((res) => {
          this.sceneList = res.data || [];
        });
      }
      this.sceneList = [];
      this.invoiceList = [];
      this.manageForm.scenarioType = "";
      this.manageForm.invoiceType = "";
    },
    // 选择场景类型
    sceneTypeChange() {
      if (this.manageForm.scenarioType) {
        // 获取发票类型
        GetBTaskType({
          level: 3,
          code: this.manageForm.scenarioType,
        }).then((res) => {
          this.invoiceList = res.data || [];
        });
      }
      this.invoiceList = [];
      this.manageForm.invoiceType = "";
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.tableData = [];
      this.getBStatementMenuStatus();
    },
    clear() {
      this.$refs["manageForm"].resetFields();
      this.manageForm.statusList = [];
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      // 获取列表
      this.getGoodsList();
    },
    // 获取列表
    getGoodsList() {
      this.loading = true;
      let statusList = [];
      if (!this.manageForm.statusList.length  || this.manageForm.statusList[0]=="") {
        this.statusList.map((item) => {
          item.Vals.map((i) => {
            statusList.push(i);
          });
        });
      } else {
        statusList = JSON.parse(JSON.stringify(this.manageForm.statusList));
      }
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        ...this.manageForm,
        dateStart: this.manageForm.loadingTime
          ? this.manageForm.loadingTime[0]
          : "",
        dateEnd: this.manageForm.loadingTime
          ? this.manageForm.loadingTime[1]
          : "",
        menuEnum: this.activeCmpt,
        statusList,
        PayModeID:this.manageForm.PayModeID
      };
      GetStatementList(params)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 打开结算单明细列表
    openDetail(row, type) {
      this.dialogType = type;
      this.currentItem = row;
      this.detailTableDialog = true;
      this.detailTableLoading = true;
      this.getInvoiceTitleInfo();
      if (
        this.activeCmpt == "SellersUpload" ||
        this.activeCmpt == "SellersCancel" ||
        this.dialogType == "billInfo"
      ) {
        GetStatementDetailList({
          pageSize: this.paginationDetail.pagesize,
          pageIndex: this.paginationDetail.page,
          bsid: this.currentItem.BSID,
        })
          .then((res) => {
            this.detailTableList = res.data.DataList;
            this.paginationDetail.total = Number(res.data.TotalCount);
          })
          .finally(() => {
            this.detailTableLoading = false;
          });
      } else if (
        (this.activeCmpt == "SellersAlready" ||
          this.activeCmpt == "SellersComplete") &&
        this.dialogType != "billInfo"
      ) {
        GetInvoicePackages({
          bsid: this.currentItem.BSID,
          applicantUserID: this.settlementInfo.ApplicantUserID,
        })
          .then((res) => {
            this.detailInvoiceTableList = res.data || [];
            this.paginationDetail1.total = this.detailInvoiceTableList.length;
          })
          .finally(() => {
            this.detailTableLoading = false;
          });
      }
    },
    // 获取六要素等开票信息
    async getInvoiceTitleInfo() {
      await GetStatementInfo({
        BSID: this.currentItem.BSID,
      }).then((res) => {
        this.settlementInfo = res.data || {};
      });
    },
    //分页控件页码change事件回调
    handleCurrentDetailChange(e) {
      this.paginationDetail.page = e;
      // 获取列表
      this.openDetail(this.currentItem, this.dialogType);
    },
    //分页控件页码change事件回调
    handleCurrentDetailChange1(e) {
      this.paginationDetail1.page = e;
    },
    // 同意开票
    agreeInvoice(row) {
      this.currentItem = row;
      if (!localStorage.getItem("isNotPromptAgain")) {
        this.flag.agreeInvoiceDialog = true;
      } else {
        this.confirmAgreeInvoice();
      }
    },
    //  确认同意开票
    confirmAgreeInvoice() {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      AgreeStatement({
        BSID: this.currentItem.BSID,
      })
        .then((res) => {
          this.$message.success("同意开票成功");
          this.flag.agreeInvoiceDialog = false;
          this.detailTableDialog = false;
          this.getGoodsList();
        })
        .finally(() => {
          loading.close();
        });
    },
    //  拒绝开票
    refuseInvoice(row) {
      this.currentItem = row;
      this.flag.refuseInvoiceDialog = true;
      this.refuseMark = "";
      this.choseRefuseMark = [];
    },
    // 确认拒绝开票
    confirmRefuseInvoice() {
      if (this.refuseMark == "" && !this.choseRefuseMark.length) {
        this.$message.warning("请选择或输入拒绝原因！");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let remark = "";
      if (this.choseRefuseMark.length) {
        for (let i = 0; i < this.choseRefuseMark.length; i++) {
          remark += `${i + 1}.${this.choseRefuseMark[i]}`;
        }
      }
      remark = this.refuseMark
        ? remark + `${this.choseRefuseMark.length + 1}.${this.refuseMark}`
        : remark;

      RefusedStatement({
        BSID: this.currentItem.BSID,
        remark: "您好：" + remark,
      })
        .then((res) => {
          this.$message.success("拒绝开票成功");
          this.manageForm.statusList = [];
          this.activeCmpt = "SellersCancel";
          this.flag.refuseInvoiceDialog = false;
          this.detailTableDialog = false;
          this.search();
        })
        .finally(() => {
          loading.close();
        });
    },
    // 存储是否不再点击
    changeIsPrompt() {
      localStorage.setItem("isNotPromptAgain", this.isNotPromptAgain);
    },
    // 立即开票
    toInvoice(row) {
      this.$router.push({
        path: "/commission/index/purchaseInvoicingProcess",
        query: {
          bsid: row.BSID,
          activeCmpt: this.activeCmpt,
          status: row.Status,
          taskTypeID: row.TaskTypeID,
        },
      });
    },
    // 获取发票包集合
    getUploadInvoiceList(row) {
      this.flag.invoicePreviewLoading = true;
      GetUploadInvoiceList({
        bpid: row.BPID,
      })
        .then((res) => {
          this.flag.invoicePreviewDialog = true;
          this.invoicePics = [];
          res.data.Normals.map((it) => {
            this.invoicePics.push(it.InvoiceImgUrl);
          });
          res.data.Specials.map((it) => {
            this.invoicePics.push(it.InvoiceImgUrl);
          });
          if (!this.invoicePics.length) {
            this.$message.warning("暂无可查看的发票！");
            return;
          } else {
            this.currentPicIdx = 0;
          }
        })
        .finally((res) => {
          this.flag.invoicePreviewLoading = false;
        });
    },
    // 上一张
    toPrevious() {
      if (this.currentPicIdx == 0) {
        this.$message.warning("已经是第一张了！");
        return;
      } else {
        this.currentPicIdx -= 1;
      }
    },
    // 下一张
    toNext() {
      if (this.currentPicIdx == this.invoicePics.length - 1) {
        this.$message.warning("已经是最后一张了！");
        return;
      } else {
        this.currentPicIdx += 1;
      }
    },
    // 下载
    downloadFile(type) {
      let ajaxFunObj = {
        invoice: DownloadInvoiceAll,
        tax: DownloadTaxExcel,
        bill: DownloadBillExcel,
      };
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ajaxFunObj[type](
        {
          bsid: this.currentItem.BSID,
          applicantUserID: this.settlementInfo.ApplicantUserID,
        },
        {
          responseType: "blob",
        }
      ).catch((err) => {
        let { data } = err;
        let that = this;
        if (err.type == "application/json") {
          let reader = new FileReader();
          reader.readAsText(err);
          reader.onload = function (e) {
            const { msg } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg}`,
              type: "error",
            });
          };
          return;
        }

        const blob = new Blob([data]);
        let fileName = err.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        } 
      }).finally(()=>
    {
      loading.close();
    });
    },
  },
  created() {
    this.activeCmpt = this.$route.query.activeCmpt
      ? this.$route.query.activeCmpt
      : "SellersUpload";
    this.getBStatementMenuStatus();
    // 获取业务类型
    GetBTaskType({
      level: 1,
      code: "",
    }).then((res) => {
      this.businessList = res.data || [];
    });
    GetPaymentMethod({
            bTaskTypeCode: '1',
        }).then(res=>{
            this.PayModeIDList=res.data ||[]
        })
  },
  computed: {
    // 列表分页
    currentTableData() {
      try {
        return this.detailInvoiceTableList.slice(
          this.paginationDetail1.pagesize * (this.paginationDetail1.page - 1),
          this.paginationDetail1.pagesize * this.paginationDetail1.page
        );
      } catch (error) {}
    },
  },
  components: {
    EvidenceInfo,
    TXmap
  },
};
</script>
<style lang="scss">
.purchase-invoicing {
  .settleBox {
    margin: 10px 0px;
    padding: 10px 16px;
  }

  .queryForm {
    display: block;

    .el-form-item {
      display: flex;
      align-items: center;

      .el-form-item__label {
        width: 100px;
      }

      .el-form-item__content {
        width: 200px;
      }
    }
  }

  .el-dialog__body {
    padding: 10px 20px 30px;
  }

  .el-dialog__footer {
    text-align: center;
  }
}

.detailTableDialog .el-dialog {
  margin-top: 9vh !important;
}
.imageError
{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   span{
     color: red;
     font-size: 20px;
   }
}
</style>
